// Dependencies
import React from "react";
// Hooks
// ...
// Context
// ...
// Components
import Artwork from "../components/Artwork";
import RichText from "../components/RichText";

const TrackViewArtwork = ({ projectLink, artwork, children }) => {

  return (
    <div className="track-view-artwork" style={{
      background: artwork.colours.secondary,
      color: artwork.colours.primary }}
    >
      <div className="artwork__wrapper">
        <Artwork {...artwork} />
        {projectLink && (
          <div className="artwork__titles">
            <RichText content={projectLink.titles} />
          </div>
        )}
        {projectLink && (
          <div className="artwork__caption p3">
            <RichText content={projectLink.caption} />
          </div>
        )}
				{children}
      </div>
    </div>
  )
}

export default TrackViewArtwork
