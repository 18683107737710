// Dependencies
import React from "react";
import { graphql } from "gatsby";
import PropTypes from "prop-types";
// Hooks
// import useShare from "../hooks/useShare";
// Context
// ...
// Components
import Layout from "../components/Layout";
import Playlist from "../components/Playlist";

const PlaylistPage = ({ data, pageContext: { locale } }) => {

  // Content
  const { title, tracks } = data.contentfulPlaylist;

  return (
    <Layout
      title={`Listen to ${title} | MSCTY.SPACE`}
      headerTitles={null}
      description={null}
      shareImage={null}
			locale={locale}
      colours={{
        primary: '#FFFFFF',
        secondary: '#0B0B0A'
      }}
      isPage={false}
    >
			{tracks && (
				<Playlist {...data.contentfulPlaylist} locale={locale} />
			)}
    </Layout>
  )
}

PlaylistPage.propTypes = {
  data: PropTypes.object.isRequired
}

export default PlaylistPage

export const PlaylistPageQuery = graphql`
  query PlaylistPageQuery($slug: String!, $locale: String!) {
    contentfulPlaylist(
			slug: { eq: $slug }, 
			node_locale: { eq: $locale }
		) {
      ...ContentfulPlaylistFragment
    }
  }
`
