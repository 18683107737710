// Dependencies
import React from "react";
import { graphql, Link } from "gatsby";
// Hooks
import useShare from "../hooks/useShare";
import useMicrocopy from "../hooks/useMicrocopy";
// Context
// ...
// Components
import Artwork from "../components/Artwork";
import TrackList from "../components/TrackList";
import TrackListMap from "../components/TrackListMap";
import TrackViewArtwork from "../components/TrackViewArtwork";
import RichText from "../components/RichText";

const Playlist = ({ locale, ...props }) => {

  // Content
	const { className = "", title, slug, tracks, artwork, projectLink, description } = props;
  const hasLocations = tracks && tracks.find(track => track.location);
  const shareUrl = `https://mscty.space/listen/${slug}`;

	const { viewProject, sharePlaylist, home } = useMicrocopy(locale)

  // UI
  const { share, hasShared } = useShare({ url: shareUrl });

  return (
    <div className={`listen-page ${className}`}>
			<div className="track-view">
				<div className="track-view__left">
					{hasLocations ? (
						<TrackListMap {...props} locale={locale} />
					) : artwork ? (
						<TrackViewArtwork projectLink={projectLink} artwork={artwork} />
					) : null}
				</div>
				<div className="track-view__right">
					<div className="track-list">
						<div className="track-list__info innerx2">
							{artwork && hasLocations && (
								<div className="artwork--square">
									<Artwork {...artwork} />
								</div>
							)}
							<div className="description p2">
								<h1>{title}</h1>
								{description && (
									<div className="p3 mb">
										<RichText content={description} />
									</div>
								)}
								<Link to="/listen" className="btn mr">{home}</Link>
								{projectLink && (
									<Link to={projectLink.category ? `/${projectLink.category.slug}/${projectLink.slug}` : `/${projectLink.slug}`} className="btn mr">{viewProject}</Link>
								)}
								<button className="btn" onClick={share}>{hasShared || sharePlaylist}</button>
							</div>
						</div>
						<TrackList {...props} locale={locale} />
					</div>
				</div>
			</div>
		</div>
  )
}

export default Playlist

export const ContentfulPlaylistFragment = graphql`
	fragment ContentfulPlaylistFragment on ContentfulPlaylist {
		title
		slug
		artwork {
			...ContentfulArtworkFragment
		}
		tracks {
			...ContentfulTrackFragment
		}
		description {
			raw
		}
		projectLink {
			slug
			category {
				slug
			}
			cover {
				...ContentfulArtworkFragment
			}
			thumbnail {
				...ContentfulArtworkFragment
			}
			titles {
				raw
			}
			caption {
				raw
			}
		}
	}
`